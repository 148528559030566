import Item from './Item.vue'
import ItemCreate from './Create.vue'
import ItemEdit from './Edit.vue'



export default [
    {
        path: '/item',
        name: 'Item',
        component: Item
    },
    {
        path: '/item/create',
        name: 'ItemCreate',
        component: ItemCreate
    },
    {
        path: '/item/edit/:id',
        name: 'ItemEdit',
        component: ItemEdit
    },
]
